<template>
  <div class="container-fluid flex-fill">
    <div class="row">
      <div class="col-md-7 bg-white vh-100 d-flex justify-content-center col-xs-12 d-none d-md-flex col-lg-5">
        <img src="@/assets/logo.svg" class="img-fluid d-block m-5" style="width:60%" />
      </div>
      <div class="col-md-5 vh-100 d-flex align-items-center justify-content-center bg-primary bg-gradient bg-opacity-75 col-xs-12 col-lg-7">
        <div class="mx-md-5 mx-3 w-50">
          <h1 class="text-center mb-5 text-white">用户登录</h1>
          <div class="form-floating mb-3 flex-fill">
            <input type="text" class="form-control" id="exampleInputEmail1" v-model="username" name="exampleInputEmail1" autocomplete="off">
            <label for="exampleInputEmail1">用户名</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control" id="exampleInputPassword1" name="exampleInputPassword1" v-model="passwd" autocomplete="off">
            <label for="exampleInputPassword1">密码</label>
          </div>
          <div class="col-12 d-grid mt-4">
            <button class="btn btn-primary  btn-lg mt-2 mt-sm-5" type="button" @click="login">登录</button>
          </div>
          <span class="form-text text-danger mt-3 text-center">{{errorinfo}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";


export default {
  name: "App",
  data () {
    return {
      username: '',
      passwd: '',
      errorinfo: ''
    }
  },
  mounted: function(){
    axios.get(process.env.VUE_APP_API_URL + "/do_login/");
  },
  methods: {
    login: function(){
      axios.post(process.env.VUE_APP_API_URL + "/do_login/", {
          exampleInputEmail1: this.username,
          exampleInputPassword1: this.passwd
        }
      ).then(response=>{
        if (response.data.state === 'success') {
          window.location = '/';
        }
      }, error=>{
        this.errorinfo = error.response.data.message;
      });
    }
  }
};
</script>

<style>
@include media-breakpoint-up(sm) {
  .w-100 {
    width: 100% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-25 {
    width: 25% !important;
  }
}
@include media-breakpoint-up(md) {
  .w-100 {
    width: 100% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-25 {
    width: 25% !important;
  }
}
@include media-breakpoint-up(lg) {
  .w-100 {
    width: 100% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-25 {
    width: 25% !important;
  }
}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}
</style>
